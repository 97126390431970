import { css } from "styled-components"

// Styling
import fonts, { weights } from "./fonts"
import { applyResponsive, Breakpoint } from "./responsive"

// Types
import { TextStyleType } from "../common/models/typography"

// Text styles
export default {
  heading: css`
    font-family: ${fonts.primary};
    font-size: 36px;
    font-weight: ${weights.bold};
    line-height: 0.86;
    letter-spacing: 0;

    ${applyResponsive(
      { from: Breakpoint.M },
      css`
        font-size: 46px;
        line-height: 0.913;
        letter-spacing: 1.2px;
      `
    )}

    ${applyResponsive(
      { from: Breakpoint.L },
      css`
        font-size: 72px;
        line-height: 0.916;
        letter-spacing: 2px;
      `
    )}
  `,
  subHeading: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: ${weights.bold};
    line-height: 1.187;
    letter-spacing: 0;

    ${applyResponsive(
      { from: Breakpoint.M },
      css`
        font-size: 22px;
        line-height: 1.36;
      `
    )}

    ${applyResponsive(
      { from: Breakpoint.L },
      css`
        font-size: 26px;
        line-height: 1.15;
      `
    )}
  `,
  title: css`
    font-family: ${fonts.primary};
    font-size: 24px;
    font-weight: ${weights.bold};
    line-height: 1.33;
    letter-spacing: 0.1px;

    ${applyResponsive(
      { from: Breakpoint.M },
      css`
        font-size: 22px;
        line-height: 1.36;
      `
    )}

    ${applyResponsive(
      { from: Breakpoint.L },
      css`
        font-size: 32px;
        line-height: 1.312;
        letter-spacing: 0;
      `
    )}
  `,
  label: css`
    font-family: ${fonts.primary};
    font-size: 18px;
    font-weight: ${weights.bold};
    line-height: 1.33;
    letter-spacing: 0;

    ${applyResponsive(
      { from: Breakpoint.M },
      css`
        line-height: 1.5;
      `
    )}
  `,
  labelSmall: css`
    font-family: ${fonts.primary};
    font-size: 14px;
    font-weight: ${weights.bold};
    line-height: 1.36;
    letter-spacing: 0;

    ${applyResponsive(
      { from: Breakpoint.M },
      css`
        font-size: 16px;
        line-height: 1.5;
      `
    )}
  `,
  bodyLarge: css`
    font-family: ${fonts.secondary};
    font-size: 16px;
    font-weight: ${weights.regular};
    line-height: 1.36;
    letter-spacing: 0.2px;

    ${applyResponsive(
      { from: Breakpoint.M },
      css`
        font-size: 22px;
      `
    )}
  `,
  body: css`
    font-family: ${fonts.secondary};
    font-size: 16px;
    font-weight: ${weights.regular};
    line-height: 1.5;
    letter-spacing: 0.2px;

    ${applyResponsive(
      { from: Breakpoint.L },
      css`
        font-size: 18px;
        line-height: 1.44;
      `
    )}
  `,
  bodySmall: css`
    font-family: ${fonts.secondary};
    font-size: 16px;
    font-weight: ${weights.regular};
    line-height: 1.5;
    letter-spacing: 0.2px;
  `,
}

export const defaultTextStyle: TextStyleType = "body"
