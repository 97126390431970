import React from "react"

// Styling
import colors from "../../styles/colors"

import iconMap from "./iconMap"

export type IconType = keyof typeof iconMap

export interface IIconProps {
  color?: string
  size?: number
}

export type IProps = IIconProps & {
  type: IconType
}

const Icon: React.FCS<IProps> = ({
  type,
  color = colors.background,
  size,
  className,
}) => {
  const IconComponent = iconMap[type]

  return <IconComponent className={className} color={color} size={size} />
}

export default Icon
