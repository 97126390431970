// Global stylesheet
import { createGlobalStyle } from "styled-components"

// Styling
import colors from "./colors"
import textStyles from "./typography"
import fonts from "./fonts"

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    color: ${colors.black};
    background-color: ${colors.white};
    font-family: ${fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  p {
    ${textStyles.body};
  }

  a {
    text-decoration: underline;
    color: ${colors.black};
  }
`

export default GlobalStyle
