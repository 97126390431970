import { css, FlattenSimpleInterpolation } from "styled-components"
import { IScreenRange, IScreenRanges } from "../common/models/responsive"
import { IGridConfig } from "../common/models/grid"

// A breakpoint defines exactly where a responsive trigger happens
export enum Breakpoint {
  S = 320,
  M = 768,
  L = 1024,
  NAV = 1200,
  XL = 1440,
  XXL = 1920,
}

export const gridConfig: IGridConfig = {
  breakpoints: [
    // Note: these may not correspond w/ already defined breakpoints
    // eg. the 'S' breakpoint is not needed in the grid
    {
      name: "s",
      startsFrom: Breakpoint.S,
      gutterWidth: 20,
      maxColumns: 2,
    },
    {
      name: "m",
      startsFrom: Breakpoint.M,
      gutterWidth: 16,
      maxColumns: 10,
    },
    {
      name: "l",
      startsFrom: Breakpoint.L,
      gutterWidth: 20,
      maxColumns: 12,
    },
  ],
}

// A screen range defines a range throughout which the styles stay the same
export const screenRanges: IScreenRanges = {
  s: { from: 0, to: Breakpoint.M - 1 },
  m: { from: Breakpoint.M, to: Breakpoint.L - 1 },
  l: { from: Breakpoint.L },
}

/**
 * Create the needed CSS for a given media query (works like a mixin)
 * @param range The range to use (passed styles will only be applied if screen size corresponds to this range)
 * @param styles The styles to conditionally apply
 */
export const applyResponsive = (
  range: IScreenRange,
  styles: FlattenSimpleInterpolation | string
) => {
  let mediaQuery = "@media screen"
  // Conditionally build the media query declaration, based on the set limits
  if (range.from && range.to) {
    mediaQuery += ` and
      (min-width: ${range.from / 16}em) and
      (max-width: ${(range.to - 1) / 16}em)`
  } else if (range.from && !range.to) {
    mediaQuery += ` and
      (min-width: ${range.from / 16}em)`
  } else if (!range.from && range.to) {
    mediaQuery += ` and
      (max-width: ${(range.to - 1) / 16}em)`
  }

  return css`
    ${mediaQuery} {
      ${styles}
    }
  `
}
