import React from "react"

// Components
import Footer from "../components/Footer"

// Styling
import CSSReset from "../styles/reset"
import GlobalStyle from "../styles/"

const Layout: React.FCS = ({ children }) => (
  <>
    <CSSReset />
    <GlobalStyle />
    <main>{children}</main>
    <Footer />
  </>
)

export default Layout
