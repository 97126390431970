import React from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Components
import Container from "./Container"
import { GridRow, GridCol } from "./grid"
import Text from "./Text"
import Link from "./Link"
import SocialMediaButton from "./SocialMediaButton"

// Styling
import colors from "../styles/colors"
import { applyResponsive, Breakpoint } from "../styles/responsive"
import textStyles from "../styles/typography"

// Types
import IFooterData from "../common/models/footer"
import GatsbyLink from "gatsby-link"

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query Footer {
      prismicFooter {
        data {
          footerTitle: footer_title {
            text
          }
          address
          emailAddress: email_address
        }
      }
      prismicSocialMediaChannels {
        data {
          socialMedia: social_media {
            type
            url
          }
        }
      }
    }
  `)

  const rawFooterData = data.prismicFooter.data
  const rawSocialsData = data.prismicSocialMediaChannels.data

  const footerData: IFooterData = {
    footerTitle: rawFooterData.footerTitle.text,
    address: rawFooterData.address,
    emailAddress: rawFooterData.emailAddress,
    socialMedia: rawSocialsData.socialMedia,
  }

  return (
    <FooterWrapper>
      <Container>
        <StyledGridRow>
          <ContentWrapper s={2} mOffsetLeft={1} m={8} lOffsetLeft={1} l={4}>
            <StyledText tag="h2" textStyle="title" color="white">
              {footerData.footerTitle}
            </StyledText>
            {footerData.address && <Address>{footerData.address}</Address>}
            {footerData.emailAddress && (
              <Email
                type="external"
                url={`mailto:${footerData.emailAddress}`}
                caption={footerData.emailAddress}
                textStyle="body"
              />
            )}
            <StyledLink to="/privacy">Privacy policy</StyledLink>
            {footerData.socialMedia?.length > 0 && (
              <SocialsWrapper>
                {footerData.socialMedia.map((item) => (
                  <StyledSocialMediaButton
                    key={item.url}  
                    url={item.url}
                    type={item.type}
                  />
                ))}
              </SocialsWrapper>
            )}
          </ContentWrapper>
          <GridCol s={2} mOffsetLeft={1} m={8} l={6} lOffsetLeft={0} lOffsetRight={1}>
            <FirstText textStyle="subHeading" color="white">
              Our media partners
            </FirstText>
            <StyledGridRow>
              <ContentWrapper s={2} m={5} l={6}>
                <SecondTitleText textStyle="label" color="white">
                  Jungle Minds
                </SecondTitleText>
                <SecondText textStyle="bodySmall" color="white">
                  is an independent digital agency that combines business
                  strategy with the power of making. Through research, design
                  and technology, they find new ways to create value for
                  end-users. And through that, create tangible business impact
                  for their clients.
                  <br />
                  <br />
                  <div>
                    <StyledColorLink href="https://www.jungleminds.com/">
                      Learn more about Jungle Minds here
                    </StyledColorLink>
                  </div>
                </SecondText>
                <StyledLogoSecond
                  src={require("../images/JM-Lockup.png")}
                ></StyledLogoSecond>
              </ContentWrapper>
              <ContentWrapper s={2} m={5} l={6}>
                <SecondTitleText textStyle="label" color="white">
                  AuxCode
                </SecondTitleText>
                <SecondText textStyle="bodySmall" color="white">
                  is a pluggable full service digital products development and
                  operation agency, following the talent scouting & nurseries
                  approach of Dahoum. Through close and long term engagements
                  they provide flexibility and business continuity. In some
                  cases, also as technology investor.
                  <div>
                    <StyledColorLink href="https://www.auxcode.com/">
                      Learn more about AuxCode here
                    </StyledColorLink>
                  </div>
                </SecondText>
                <StyledLogoSecond
                  src={require("../images/aux_logo.svg")}
                ></StyledLogoSecond>
              </ContentWrapper>
            </StyledGridRow>
          </GridCol>
        </StyledGridRow>
      </Container>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  margin-top: 56px;
  padding: 42px 24px 64px;
  background-color: ${colors.black};

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-top: 140px;
      padding-top: 72px;
    `
  )}

  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin-top: 180px;
    `
  )}
`

const StyledGridRow = styled(GridRow)`
  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      align-items: flex-start;
      justify-content: space-between;
    `
  )}
`

const ContentWrapper = styled(GridCol)`
  ${textStyles.body}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
  color: ${colors.white};

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-bottom: 0;
    `
  )}
`

const SecondTitleText = styled(Text)`
  margin-top: 40px;
  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-top: 50px;
    `
  )}
`
const FirstText = styled(Text)`
  width: 100%;
  display: block;
`

const SecondText = styled(Text)`
  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-top: 32px;
    `
  )}
`
const StyledColorLink = styled.a`
  color: white !important;
  padding-top: 16px;
  display: inline-block;
`

const StyledText = styled(Text)`
  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-bottom: 55px;
    `
  )}
`

const StyledLogoSecond = styled.img`
  margin-top: 52px;
  width: 200px;
`

const Address = styled.address`
  margin: 0;
  font-style: normal;

  ${applyResponsive(
    { from: Breakpoint.M },
    css`
      margin-top: 8px;
    `
  )}
`

const Email = styled(Link)`
  ${textStyles.body}
  color: ${colors.white};
`

const SocialsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 70px;
  width: 100%;
`

const StyledSocialMediaButton = styled(SocialMediaButton)`
  :not(:last-child) {
    margin-right: 32px;
  }
`

const StyledLink = styled(GatsbyLink)`
  color: ${colors.white};
  margin-top: 12px;
`

export default Footer
