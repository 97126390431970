import React from "react"
import styled from "styled-components"

// Styling
import colors, { defaultTextColor } from "../styles/colors"
import textStyles, { defaultTextStyle } from "../styles/typography"

// Types
import { Color } from "../common/models/colors"
import { TextStyleType, TextTagType } from "../common/models/typography"

interface IProps {
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  tag?: TextTagType
  color?: Color
  textStyle?: TextStyleType
  textAlign?: "left" | "right" | "center"
  dangerouslySetInnerHTML?: {
    __html: string
  }
}

export const Text: React.FCS<IProps> = (props) => {
  const tag = props.tag || "p"
  const color = props.color || defaultTextColor
  const textStyle = props.textStyle || defaultTextStyle

  const marginTop = props.marginTop || 0
  const marginRight = props.marginRight || 0
  const marginBottom = props.marginBottom || 0
  const marginLeft = props.marginLeft || 0
  const textAlign = props.textAlign || "left"
  const dangerouslySetInnerHTML = props.dangerouslySetInnerHTML

  return (
    <StyledText
      className={props.className}
      as={tag}
      color={color}
      textStyle={textStyle}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      textAlign={textAlign}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {props.children}
    </StyledText>
  )
}

const StyledText = styled.span<{
  textStyle: TextStyleType
  color: Color
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  textAlign?: string
}>`
  margin-top: ${(props) => props.marginTop}px;
  margin-right: ${(props) => props.marginRight}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-left: ${(props) => props.marginLeft}px;
  color: ${(props) => colors[props.color!]};
  ${(props) => textStyles[props.textStyle]}
  text-align: ${(props) => props.textAlign};
`

export default Text
