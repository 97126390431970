import React from "react"

// Types
import { IIconProps } from "./Icon"

const Twitter: React.FCS<IIconProps> = ({ color, size, className }) => {
  const sizeProps = size ? { width: size, height: size } : {}

  return (
    <svg viewBox="0 0 23 19" fill={color} {...sizeProps} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7868 2.8347c-.8125.3561-1.6858.596-2.6024.704.9359-.5532 1.6546-1.4298 1.9923-2.4744a9.1485 9.1485 0 01-2.8775 1.0855c-.8255-.8693-2.0043-1.4125-3.3073-1.4125-2.9254 0-5.075 2.6942-4.4143 5.491-3.7646-.1861-7.1032-1.9665-9.3384-4.6726-1.187 2.0103-.6156 4.64 1.4015 5.9716a4.5567 4.5567 0 01-2.0511-.5595c-.0497 2.072 1.4548 4.0104 3.6339 4.4419a4.5992 4.5992 0 01-2.0466.0763c.576 1.7768 2.249 3.0694 4.233 3.1057-1.9048 1.4743-4.3048 2.1328-6.7084 1.8531 2.0052 1.269 4.3876 2.0093 6.9459 2.0093 8.4126 0 13.1655-7.0135 12.8784-13.304a9.1585 9.1585 0 002.261-2.3154z"
      />
    </svg>
  )
}

export default Twitter
