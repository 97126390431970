import { css } from "styled-components"

export default {
  primary: "Prompt, sans-serif",
  secondary: "'neue-haas-grotesk-display', sans-serif",
}

export const fontFaces = css`
  /* The import below includes the following fonts:
  - Neue Haas Grotesk Display Pro 55 Roman
  - Neue Haas Grotesk Display Pro 56 Italic
  - Neue Haas Grotesk Display Pro 65 Medium
  - Neue Haas Grotesk Display Pro 75 Bold */
  @import url("https://use.typekit.net/xvg1mnp.css");
`

export const weights = {
  regular: 400,
  bold: 700,
}
