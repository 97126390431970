import React from "react"
import styled, { css } from "styled-components"

// Components
import Icon from "./icons/Icon"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"
import colors from "../styles/colors"

type Type = "facebook" | "instagram" | "twitter"
type Size = "small" | "large"

interface IProps {
  type: Type
  url: string
  size?: Size
}

const SocialMediaButton: React.FCS<IProps> = ({
  type,
  url,
  size = "small",
  className,
}) => (
  <Container
    href={url}
    target="_blank"
    size={size}
    rel="noopener noreferrer"
    className={className}
  >
    <StyledIcon type={type} />
  </Container>
)

const Container = styled.a<{ size: Size }>`
  flex-shrink: 0;
  display: block;
  padding: 7px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${colors.white};

  ${({ size }) =>
    size === "large" &&
    css`
      ${applyResponsive(
        { from: Breakpoint.M },
        css`
          padding: 8px;
          width: 48px;
          height: 48px;
        `
      )}

      ${applyResponsive(
        { from: Breakpoint.L },
        css`
          padding: 16px;
          width: 88px;
          height: 88px;
        `
      )}
    `}
`

const StyledIcon = styled(Icon)`
  width: 100%;
  height: 100%;
`

export default SocialMediaButton
