import React from "react"
import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"

// Styles
import colors from "../styles/colors"
import textStyles from "../styles/typography"

// Types
import { Color } from "../common/models/colors"
import Icon, { IconType } from "./icons/Icon"
import { TextStyleType } from "../common/models/typography"

type LinkType = "internal" | "external" | "nolink"

interface IProps {
  type: LinkType
  caption?: string
  url?: string
  disabled?: boolean
  color?: Color
  textStyle?: TextStyleType
  icon?: IconType
  iconColor?: Color
  iconSize?: number
  underlined?: boolean
  isActive?: boolean
  onClick?: () => void
}

const Link: React.FCS<IProps> = (props) => {
  const color = props.color ?? "black"
  const iconColor = props.iconColor ?? color
  const iconSize = props.iconSize || 18

  const renderChildren = () => (
    <>
      {props.icon !== undefined && (
        <Icon size={iconSize} color={colors[iconColor]} type={props.icon} />
      )}
      {props.caption && <LinkLabel>{props.caption}</LinkLabel>}
      {props.children && props.children}
    </>
  )

  const sharedProps = {
    className: props.className,
    $underlined: props.underlined,
    onClick: props.onClick,
    disabled: props.disabled,
    $isActive: props.isActive,
    $textStyle: props.textStyle ?? "label",
    color,
  }

  return props.url ? (
    <>
      {props.type === "internal" && (
        <InternalLink to={props.url} {...sharedProps}>
          {renderChildren()}
        </InternalLink>
      )}
      {props.type === "external" && (
        <ExternalLink href={props.url} target="_blank" {...sharedProps}>
          {renderChildren()}
        </ExternalLink>
      )}
    </>
  ) : (
    <NoLink {...sharedProps}>{renderChildren()}</NoLink>
  )
}

const LinkLabel = styled.span`
  :not(:first-child) {
    margin-left: 4px;
  }
`

const sharedLinkStyles = css<
  Pick<IProps, "color" | "disabled"> & {
    $textStyle: TextStyleType
    $underlined?: boolean
    $isActive?: boolean
  }
>`
  align-items: center;
  display: inline-flex;
  color: ${(props) => colors[props.color!]};
  transition: color 0.3s ease;
  text-decoration: none;
  cursor: pointer;

  ${LinkLabel} {
    ${(props) => textStyles[props.$textStyle]}
  }

  ${(props) =>
    props.$underlined &&
    css`
      position: relative;
      margin-bottom: 4px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -4px;
        right: 0;
        height: 4px;
        background-color: ${colors.black};
      }
    `}

  ${(props) =>
    props.$isActive &&
    css`
      position: relative;
      margin-bottom: 6px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -6px;
        right: 0;
        height: 6px;
        background-color: ${colors.primary};
      }
    `}

  &[disabled] {
    color: ${colors.disabled};
    cursor: default;
    pointer-events: none;
  }
`

const ExternalLink = styled.a`
  ${sharedLinkStyles}
`

const InternalLink = styled(GatsbyLink)`
  ${sharedLinkStyles}
`

const NoLink = styled.div`
  ${sharedLinkStyles}
`

export default Link
