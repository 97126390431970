import ChevronLeft from "./ChevronLeft"
import Facebook from "./Facebook"
import Instagram from "./Instagram"
import Twitter from "./Twitter"

const iconMap = {
  chevronLeft: ChevronLeft,
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
}

export default iconMap
