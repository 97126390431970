import React from "react"
import styled, { css } from "styled-components"

// Types
import {
  IGridBreakpoint,
  IGridColProps,
  ColSpanAmountS,
  ColSpanAmountM,
  ColSpanAmountL,
} from "../../common/models/grid"

// Utils
import { findBreakpoint } from "../../utils/styleHelpers"

interface IProps extends IGridColProps {
  // Default tag name is 'div'
  tagName?: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

const GridCol: React.FCS<IProps> = ({
  tagName = "div",
  s,
  sOffsetLeft,
  sOffsetRight,
  sMaxCols,
  m,
  mOffsetLeft,
  mOffsetRight,
  mMaxCols,
  l,
  lOffsetLeft,
  lOffsetRight,
  lMaxCols,
  children,
  className,
}) => (
  <Col
    {...{
      s,
      m,
      l,
      sOffsetLeft,
      sOffsetRight,
      sMaxCols,
      mOffsetLeft,
      mOffsetRight,
      mMaxCols,
      lOffsetLeft,
      lOffsetRight,
      lMaxCols,
    }}
    as={tagName}
    className={className}
  >
    {children}
  </Col>
)

const getColStyle = (
  bp: IGridBreakpoint,
  colSpan?: ColSpanAmountS | ColSpanAmountM | ColSpanAmountL,
  maxColumns?: number,
  offsetLeft?: number,
  offsetRight?: number
) => {
  const size = bp.startsFrom
  const nrOfCols = maxColumns ?? bp.maxColumns
  const totalGutter = bp.gutterWidth * (nrOfCols - 1)
  const singlePureColWidth = `(100% - ${totalGutter}px) / ${nrOfCols}`
  const totalPureColWidth = colSpan && `${singlePureColWidth} * ${colSpan}`
  const gutterBetweenCols = colSpan && `${bp.gutterWidth * (colSpan - 1)}px`

  const calculatedWidth = `calc(${totalPureColWidth} + ${gutterBetweenCols})`

  return css`
    @media only screen and (min-width: ${size}px) {
      ${calculatedWidth &&
      `
        flex-basis: ${calculatedWidth};
        max-width: ${calculatedWidth};
      `}

      ${typeof offsetLeft !== "undefined" &&
      `margin-left: calc((${singlePureColWidth} + ${bp.gutterWidth}px) * ${offsetLeft});`}

      ${typeof offsetRight !== "undefined" &&
      `margin-right: calc((${singlePureColWidth} + ${bp.gutterWidth}px) * ${offsetRight});`}
    }
  `
}

export const Col = styled.div<IGridColProps>`
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100%;

  ${({ s, sOffsetLeft, sOffsetRight, sMaxCols }) =>
    (s || sOffsetLeft || sOffsetRight) &&
    getColStyle(findBreakpoint("s")!, s, sMaxCols, sOffsetLeft, sOffsetRight)}

  ${({ m, mOffsetLeft, mOffsetRight, mMaxCols }) =>
    (m || mOffsetLeft || mOffsetRight) &&
    getColStyle(findBreakpoint("m")!, m, mMaxCols, mOffsetLeft, mOffsetRight)}

  ${({ l, lOffsetLeft, lOffsetRight, lMaxCols }) =>
    (l || lOffsetLeft || lOffsetRight) &&
    getColStyle(findBreakpoint("l")!, l, lMaxCols, lOffsetLeft, lOffsetRight)}
`

export default GridCol
