import React from "react"

// Types
import { IIconProps } from "./Icon"

const Facebook: React.FCS<IIconProps> = ({ color, size, className }) => {
  const sizeProps = size ? { width: size, height: size } : {}

  return (
    <svg viewBox="0 0 13 24" fill={color} {...sizeProps} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6544 8.5048H.8027v3.8023h2.8517v11.4067h4.7528V12.3071h3.4619l.3403-3.8023H8.4072V6.9203c0-.9078.1825-1.2671 1.0599-1.2671h2.7423V.9004H8.5897c-3.4182 0-4.9353 1.5047-4.9353 4.3868v3.2176z"
      />
    </svg>
  )
}

export default Facebook
