import React from "react"

// Types
import { IIconProps } from "./Icon"

const ChevronLeft: React.FCS<IIconProps> = ({ color, size, className }) => {
  const sizeProps = size ? { width: size, height: size } : {}

  return (
    <svg viewBox="0 0 24 24" fill="none" {...sizeProps} className={className}>
      <path
        fill={color}
        d="M4.5762 12.2524L16.2983 1.9998l3.7018 3.2377L8.278 15.4901z"
      />
      <path
        fill={color}
        d="M7.7012 9.5093l11.7221 10.2526-3.7017 3.2377L3.9994 12.747z"
      />
    </svg>
  )
}

export default ChevronLeft
