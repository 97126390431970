import { gridConfig } from "../styles/responsive"
import { IGridCols } from "../common/models/grid"
import { Breakpoint } from "../styles/responsive"

export type AspectRatio = {
  [col in keyof IGridCols]: number
}

type GetAspectRatio = (aspectRatio: AspectRatio) => number

export const findBreakpoint = (bpName: string) =>
  gridConfig.breakpoints.find((bp) => bp.name === bpName)

export const getAspectRatio: GetAspectRatio = (aspectRatio) => {
  if (typeof window === "undefined") return 1

  const screenWidth = window.innerWidth

  if (screenWidth >= Breakpoint.L && aspectRatio.l) {
    return aspectRatio.l
  } else if (screenWidth >= Breakpoint.M && aspectRatio.m) {
    return aspectRatio.m
  } else if (aspectRatio.s) {
    return aspectRatio.s
  } else {
    return 1
  }
}
