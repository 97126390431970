import React from "react"
import styled, { css } from "styled-components"

// Styles
import { applyResponsive, Breakpoint } from "../../styles/responsive"

// Utils
import { findBreakpoint } from "../../utils/styleHelpers"

interface IProps {
  // Default tag name is 'div'
  tagName?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  alignType?: "center" | "end" | "reverse"
  withGutter?: boolean
}

const GridRow: React.FCS<IProps> = ({
  tagName = "div",
  alignType,
  withGutter = true,
  children,
  className,
}) => (
  <Row
    alignType={alignType}
    as={tagName}
    withGutter={withGutter}
    className={className}
  >
    {children}
  </Row>
)

const Row = styled.div<Pick<IProps, "alignType" | "withGutter">>`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  ${(props) =>
    props.alignType === "center" &&
    css`
      justify-content: center;
    `}
  ${(props) =>
    props.alignType === "end" &&
    css`
      justify-content: flex-end;
    `}

    ${(props) =>
    props.withGutter &&
    applyResponsive(
      { to: Breakpoint.M },
      css`
        padding-right: ${findBreakpoint("s")?.gutterWidth}px;
        padding-left: ${findBreakpoint("s")?.gutterWidth}px;
      `
    )}
`

export default GridRow
