import { Color } from "../common/models/colors"

export default {
  accent: "#DCC7AA",
  background: "#1D1818",
  black: "#000000",
  disabled: "#979797",
  disabledBg: "#EFEFEF",
  error: "#E90303",
  link: "#0098FD",
  neutral: "#F5EFE7",
  primary: "#FF9500",
  primaryLight: "#FFAA33",
  white: "#FFFFFF",
}

/**
 * Some components allow color customization. Since that's
 * optional, use this as a default fallback color in case
 * it's unset
 */
export const defaultColor: Color = "primary"

export const defaultTextColor: Color = "black"
