import React from "react"
import styled, { css } from "styled-components"

// Styling
import { applyResponsive, Breakpoint } from "../styles/responsive"

const Container: React.FCS = ({ className, children }) => (
  <Wrapper className={className}>{children}</Wrapper>
)

const Wrapper = styled.div`
  ${applyResponsive(
    { from: Breakpoint.L },
    css`
      margin: 0 auto;
      max-width: 1440px;
    `
  )}
`

export default Container
